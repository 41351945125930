import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../../../../components/structure/layout'

const TheSmallVillageTavernPage = () => (
  <Layout>
    <Helmet title="The small village tavern - Warcraft Fiction" />
    <section>
      <h1>The small village tavern</h1>

      <div className="disclaimer-container">
        <span className="year">~ year: 2003 | Georgi's age: 18</span>
        <span className="disclaimer">
          Unedited things the way they were in the late 90s and early 2000s
        </span>
      </div>

      <div>
        <pre>
          Chapter 1 – The Very Beginning
          <br />
          <br />
          The small village tavern, called ‘The Rune Stone’, was settled in a
          small valley, between the great woods of the Silvermoon forest and the
          Darrowmere lake. The poor light of an old lamp could not be enough to
          lighten up the tavern’s main hall in this autumn night. The barkeeper
          looked worried, for there weren’t far too many customers this night.
          Only two main groups of people were symmetrically positioned in the
          opposite corners of the pub. They were laughing and joking with one
          another as the hours passed. Two friends, who looked like ordinary
          peasants, represented one of the groups. They talked, like everyone
          else did, about the recent day, passed in hard work.
          <br />
          In that same moment the door opened, waving the dry, passive air
          changing it into night’s cool breeze. Everyone felt the refreshing
          ‘wind’ and looked at the old wooden door. A short old man went inside
          and sat over the two peasants. They paid no attention to him, as he
          ordered some beer. He kept drinking ale after ale, and kept listening
          to the peasants. They, however, were so absorbed by their
          conversation, that they even spoke of the old prophecies and myths and
          at one point the old man even joined the conversation. Then the two
          friends spoke of the ancient days and the elves. There were many, many
          rumors that they spoke of. Some said that the elves still live in the
          mountains of Quel’thalas, but no one saw them after the second and
          last invasion of the Burning Legion. Some said that the elves joined
          their old brothers – the Night Elves, and went to Kalimdor. Others
          said that the elves were imprisoned in Northrend by the last band of
          trolls and orcs. Yet, none knew the truth.
          <br />
          “This all in not true” the old man said deeply and confident in his
          words.
          <br />
          “I can tell you the story of the High Elves.” the man said loudly.
          Everybody in the tavern turned towards him.
          <br />
          “Leave that old man. He’s drunk” the other group of townsman looked
          with sympathy at him, for they all knew that many of the old man and
          women nowadays were crazed by the treacherous wars with the Burning
          Legion, the Horde, and now the Undead. Many lost their families, but
          when those stupid necromancers came and rose the dead … the situation
          got even worse. No one liked his dead relative to walk undead and seed
          chaos over his own kind. So it was very difficult for the elder people
          to get accustomed to all those horrific events.
          <br />
          “Silence! I am the prophet, and no one will ever call me that way!”
          the figure of the ‘old man’ stand up and rose as his eyes filled with
          anger. <br />
          “I failed once, yes, it is truth. But I think I did enough to pay my
          mistakes” he sat and began his tale.
          <br />
          “Many centuries ago, about 10 000 years, the Dragons ruled Azeroth.
          West of here there was an enormously huge land called Kalimdor. It
          still exists nowadays, but the half of it has sunken into the Great
          Sea. So in that same land awakened the race of the Night Elves. There
          are no other elfish races different to the Night ones. The others
          emerged later. I was one of the first to be born. Our race was
          developing quickly and steadily for we all were immortal. There was no
          death for us. We could only be killed by foe, never by the fate. No
          matter our glorious development we began creating powerful magiks for
          different tasks. Then the magic became part of our everyday life. We
          used it for everything you could imagine – even to just pick an object
          up from a distance. Foolish me, it was I who first intended to use the
          elfish magiks for a more important things. Yet, I don’t quite remember
          now, but I can tell that magiks were everywhere. I hope you get the
          picture” the prophet interrupted his speech and bought everyone, who
          listened to him, a drink. He used this short break to relax and calm
          down a little.
          <br />
          “I invented magiks that enabled world travel. You see, truth is that
          all worlds are connected to the world of the dead souls – the Twisting
          Nether. By going into the Nether, and venturing deeper into the Great
          Dark Beyond, you are able to visit almost any place in the universe. I
          did so. I’ve been to Dreanor, too. A pity it has been destroyed,
          indeed. It was very beautiful red world, full of ‘strange’ (for our
          understandings) plants and animals. That is not the point of my tale,
          though. It was then, when the Daemon Lord Sargeras detected our
          powerful magic sources. So, the Burning Legion invaded. It took us
          about 1000 years to fight for our land. Yet, the Dragons helped us and
          probably thanks to them we finally defeated Sargeras and his army. We
          pursued his army of fire stoned demons out of our world and far into
          the universe. Of course that was a battle won, but the war was still
          at hand and will always be. I understood later that those demons that
          we pursued corrupted our Orcish brothers from Draenor. This is why the
          war will never end. There will always be someone to corrupt the noble
          people, making them a grave danger indeed ” by his last sentence, the
          ‘old man’ wide opened his eyes, showing their bright and dark side
          alike.
          <br />
          “I’m beginning to have second thoughts about that old man. He could be
          one of those crazed necromancers, do you think?” a farmer asked his
          wife. She didn’t say a word, though, still staring at the ‘prophet’.
          <br />
          “So my word was about Sargeras. We entomb him west of here, not so far
          from the shores of Sunken Kalimdor. No matter that ‘great’ success I
          was banned from my homeland and send into exile. The small group of
          the Night Elves that helped me design my invention, who have vowed not
          to use magic ever again (like me) came along with me. We chose
          Quel’thalas for it was the only free region except Northrend to
          inhabit. As we traveled towards that destination we were told of the
          awakening of the mortal races. Humans, dwarfs, gnomes, trolls and
          many, many others were the first we heard about during our 6-month
          journey”.
          <br />
          <br />
          Chapter 2 – The Shores of Northrend
          <br />
          <br />
          One day, probably 2 months after we last ‘saw’ the shores of Sunken
          Kalimdor, Hyjal shouted from the top of the front mast. Everyone
          trusted him, as I did for he was my very best friend, so we all looked
          at the far northeast. Those were some white-colored shores. It was
          strange, for we all had never seen such brightly colored land … or was
          that land?
          <br />
          “It’s weird! Our priestess of the Moon has never told us of this land.
          It is not shown on any map. But I guess our people need to rest a bit
          from rowing all day and night for the last months. What do you think?”
          Hyjal looked worried about what I was going to say, but his face kept
          its usual cheerful looking mood.
          <br />
          “You decide whether we proceed, or stop for a day or two. It looks
          like we’re the next of the history writers. We found a land that no
          one knew it even existed, we may do other more important things in the
          near future. Who knows?” I replied, smiling at my friend. I then left
          him tell our people the glorious news and went to my room, for I knew
          that the next day would be a difficult one. At least for me. I laid
          down on my bed and thought about the upcoming great exploration of
          that new land. Suddenly I remembered that we hadn’t chose its new name
          yet. “I guess I’ll let Hyjal think of something. Hyjal. That name
          again. My old friend was named after Kalimdor’s sacred mountain
          summit. That was the place we had lived for hundreds of years after my
          unsuccessful experiment, until they exiled me away from my very home.
          Nonsense! It’s all my fault and I will suffer the consequences.
          Tomorrow will be a great day.” With my last thought gone I fall
          asleep.
          <br />
          As the sun rose I woke up and went outside. It was just another
          beautiful sunny day and we all gathered around Hyjal to hear what
          should we do.
          <br />
          “We cannot allow a full three-days rest in this land for it is both
          unknown and we have to proceed with our journey. We can, however,
          spend a day or mostly two, while our druids write down maps of this
          island. It is not as much time as many of us would want, but I dare to
          say it’s enough. As a matter of fact we’ve got an important problem to
          solve ahead. We’ve got to find a place to live in.” Hyjal took a deep
          breath and continued his speech.
          <br />
          “I know that many of you ask yourselves if this isn’t just the right
          place to inhabit. Well, it could be but we don’t know a thing about it
          yet. Now let’s get in the boats and raw to the shore. You got about 12
          hours to rest, walk and do whatever you want. After that we’ll meet at
          the shore again to see what the druids can tell us about that land.
          Hurry up!” the crowd around Hyjal was growing larger as his speech
          progressed and now there were about two times more people than the
          time he started. Everyone looked happy and cheerful as if an elf in
          exile could be any joyful. “You truly are a confident person, my
          friend.” I thought about Hyjal and went forward to greet him.
          <br />
          “Did you sleep well?” my friend asked.
          <br />
          “You heard about the schedule, didn’t you?” he didn’t even waited to
          answer his first question and asked again. I saw impatience in Hyjal’s
          eyes. My friend looked eager to explore this land with every passing
          moment, so I decided not to waste his time now.
          <br />
          “So we got only a day? Well, I hope the druids don’t waste their time.
          See you later.” I turned around and went back to my room to take the
          rest of my equipment. “This cannot last any long.” I thought about our
          exile. “We have to find a place to live like normal elves. I don’t
          know where are we supposed to go. I will ask Hyjal later. But what if
          this is our new home? Or what if we go live with those newborn. With
          the Humans. I’d better be going or else those questions will be the
          very end of me. To every problem there is solution, every question has
          his own answer and every quest could be completed as our ancients say.
          Calm now!” thinking over all those topics I took my bag, some elfish
          food and ran away. I was the last one at the boats, so I quickly
          jumped towards one of the full ones and found a small place to sit
          down. I thought again about those new races. Our druid scouts report
          that there are not only new races, but the dragons had disappeared
          also. Strange.
          <br />
          “So you made it anyway!” Hyjal put his arm on my shoulder and smiled
          at me.
          <br />
          “I guess I did!” words are useless when two old friends understand
          each other.
          <br />
          “RAW” I heard from behind and prepared for the splendid view that we
          will probably encounter.
          <br />
          <br />
          Chapter 3 – The Foundation of Northrend
          <br />
          <br />
          When we finally landed on the shores of Northrend I felt weird. I was
          feeling that way for the very first time. The sun was high in the sky,
          but the air was still cold. The land was colored in white and was also
          cold. Nevertheless we were all happy, for we knew that we were going
          to discover a new land. A land unknown even to the old prophets like
          my self. As I thought about all those odd things we had saw this far,
          Hyjal lead us deeper inside a white forest.
          <br />
          “Even the trees are … different!” I could not hold my thought. Hyjal
          heard me.
          <br />
          “Strange are these lands and we still have no idea of what is waiting
          for us deeper into the tree labyrinth.” Hyjal sounded strange, but I
          even did not notice that in that great moment. The captain of the ship
          shouted in the same time.
          <br />
          “Hear me brothers! We shall settle a camp in this valley. You can use
          the time until the next sunrise as you wish. The Aran Food will be
          served after about 5 hours. Don’t be late or the spirits of the
          Nephalem will be angry.” Our most ancient ones – the Spirits of the
          Nephalem. They were named after the other great mountain summit –
          Nephalem. Tradition is that the Aran Food, or also Noble Food, should
          be served every three days at the sunset. That Food is represented
          mostly by Kalimdor bread by plants from the Hyjal foothills and fresh
          ice-cold water from the Nephalem summit’s highest lake. That same lake
          was where the Nephalem brothers saved our capital forest town from
          lord Auchinduin’s attacks. Lord Auchinduin was one of the Legion’s
          leaders. It’s a long and admirable story, but its essence is that
          unity is the key to victory. “Even now, 8000 years later, I feel the
          same proud way.” the prophet looked sadly towards the tavern floor and
          unexpectedly he continued his story. “Anyway, in that moment I thought
          not about the ancient ones, but about the camp. The place looked nice
          – on the east side we had a nice white forest, while on the opposite
          we had a deep valley. Southward was the place we came from – our ship
          and the Great Sea’s bay. Finally northward was yet unknown place that
          looked like a swamp. The camp itself was nothing but a place full of
          old centaur-fur tents. It was difficult to resist the chilling cold so
          many of us stayed in the camp as guards, others like short-range
          scouts and third just hid in their own tents and slept until the time
          for the Aran Food came. I thought that there will be plenty of time to
          sleep in the near future, when we finally establish a forest town or
          at least a small valley village, so I decided to go and study this
          strange land. There were pretty few warriors that chose the adventure
          than the warm, cozy tent. I saw those adventurers lead by the captain
          himself to depart in one group to the east. To the White Forest, as we
          called it later, for it was the first white one we saw. Elfish nature!
          They always choose the forests first, than any other place to explore.
          You should know, that even the sacred forests of the Hyjal foothills
          could be more dangerous even than the Swamps of Sorrow where I firstly
          opened the…” the prophet suddenly ceased speaking. His figure
          contoured in the dark tavern hall. As a few moments passed he
          proceeded talking about those very ancient times. “I asked Hyjal if he
          could come with me. I chose for my destination the ‘swamps’, because
          there probably would be nothing to explore in the valley. The next
          part is not so important about this tale. Hyjal and I went deep into
          the swamps, but saw no enemy. Everything was calm as if this was Hyjal
          summit itself. We had to be very cautious about our every little step
          or move for the swamp was not to be underestimated. The only thing we
          saw on that ‘journey’ was a deer that sunk into the swamp.
          Unfortunately we were not able to help it. Hyjal tried but it appeared
          that this place drains magical energies as good and fast as the
          Burning Legion’s lord does. When we saw this tragic case of death I
          suggested that we go back to the camp. Hyjal instantly agreed and thus
          we took the way back. It was about 15 minutes before the Aran Food to
          be served that we returned. The ritual was as normal as ever and after
          about 2 hours of conjuring and eating our druids told us about this
          land. Northrend was a small island covered with white stuff all over.
          They told us about the different trees and bushes and so on. After the
          maps had been written down our dryad scouts finally returned. Sad news
          was what they were bearing. Although I had no doubt that this land was
          inappropriate for the Night Elves to live, the dryads confirmed my
          thoughts, too. Sad, for it doomed us to look for another free land to
          inhabit. The next morning we calmly and melancholically walked towards
          the boats and rowed to the ship. That same afternoon we set sail to
          the east… Again.” the prophet stopped to take a breath. If someone
          have entered the tavern by that very moment he would see how a bunch
          of peasants have gathered around an old man, listening to him as
          careful as possible not to miss even a word from his speech. And so
          the prophet continued his story…
          <br />
          <br />
          Chapter 4 – The Vow
          <br />
          <br />
          “I cannot clearly tell what happened next on that journey.” The old
          man began. “What I can tell, however, is that we saw no land for many
          long days and nights. Just as we supposed there were only small
          islands, which were impossible to inhabit even by such a slight group
          of elves like us. Anyway, I used that time to let alone and think over
          all my thoughts. As I did so, Hyjal and the captain commanded the
          others. Hyjal was personally told by me to send sacred owls and Druids
          of the Talon to explore the sea before us. Those druids were the ones
          that were capable of transformation to crow form and were really fast
          scouts. I finally decided what should I be able to do for my people.
          One night as I was reading the ancient tomes of nature at the small
          library, I met Kul’Dare. He was one of those Druids of the Talon that
          were already sent to scout the sea ahead.
          <br />
          “Did you encounter anything?” I asked.
          <br />
          “No. Nothing in a very long distance, according to our maps.” he
          replied coldly.
          <br />
          “I have decided!” my voice trembled as I began “We will venture deeper
          in that continent here.” I pointed at the eastern side of the map in
          the book I was currently holding. “I know that it’s dangerous but …
          what could we do? There probably are no other free pieces of land in
          the whole world.” Kul’Dare was staring at my eyes as if he could look
          through them into my very mind.
          <br />
          “Did you tell this to Elune?” Elune was the captain of the ship. I was
          angered.
          <br />
          “Elune is no one! He commands only during our sea journey! I am the
          leader of this exile.” Kul’Dare did not move a bit and stood calmly as
          if nothing happened.
          <br />
          “Calm down ancient one!” he spoke to me “I meant no harm. Truly, Elune
          should only be told of this plan. As for me, I will follow you my old
          friend.”
          <br />
          “That’s really … kind of you. I swear now to lead our race of Night
          Elves in exile to a better life without even a small bit of magic! Go
          now, and announce my words to the rest of our brethren.” Kul’Dare left
          the room and I was let alone only with my thoughts in the library.
          Strange! I felt confident and untroubled by anything. I guessed what
          our destiny will be, but the vow that I made that day, I fulfilled.”
          with his last words, the prophet examined closely every one of his
          companions in the tavern. He looked deep into their hearts and seeing
          that they are just simple, ordinary people who mean no harm to anyone,
          he proceeded his story.
          <br />
          <br />
          Chapter 5 – The Green Skins
          <br />
          <br />
          “Yet, that day I didn’t know that the lands we were going to visit
          belong to the region of Quel’thalas” the prophet looked again at the
          people around him and after a few seconds he continued. “We, however,
          were not so close to Quel’thalas. Not yet. The next day after my
          conversation with Kul’Dare, I revealed my plan and vow to the rest of
          the crew. Elune seemed to be in agreement with the plan. As for
          Kul’Dare and Hyjal, well, I was never disappointed by them and just as
          I expected they stood behind me once again. Hyjal was a man of honor.
          All he wanted was a peaceful life. He was not of those people that ask
          plenty of questions or talk all the time. I liked him. Kul’Dare, on
          the other hand, was not so quiet person. Although he too was a man of
          action, he was a bit more talkative person. So, after everyone knew
          what we should do, I send the Druids of the Talon once again to scout
          ahead. For two long days there were no news from the scouts. I began
          to torture myself for I was who send them ahead. At the sunrise of the
          3rd day Kul’Dare finally returned. I was stoned, for there was no one
          else with him. I send five scouts, for Elune!
          <br />
          “Where are the others” were the only words that slipped away from my
          mouth.
          <br />
          “Let’s go inside” said Kul’Dare and Hyjal, Elune and I went to the
          library.
          <br />
          “So” asked Elune. Kul’Dare began slowly his report.
          <br />
          “We flew about 14 hours to the east. Suddenly we saw a small island
          and decided to land and take a look. We couldn’t, though! As we
          approached the ground strange creatures began to shoot at us with
          gigantic arrows. That’s how the others died. Only Lasien survived but
          he too was wounded and after about 4 hours flying back he died. The
          only thing that saved me from their poisonous arrows was my speed.
          Unluckily the others were not as swift as I was” I couldn’t move my
          lips. I had so many, many questions that needed answers. Elune was the
          first to ask one of those questions.
          <br />
          “What were those creatures like” he asked shortly.
          <br />
          “Well, we saw them not but I gathered the impression that they were
          green skinned beings, as tall as we are, and very skilled fighters.
          The very strange thing was that they had no bows! They just threw away
          the ‘arrows’ with their own hands” Kul’Dare looked worried.
          Unnaturally Hyjal took his place in the conversation.
          <br />
          “May I suggest something master” he asked me.
          <br />
          “Sure, go ahead” I answered.
          <br />
          “If it is possible that we send a host of our people to the dwarven
          capital of Ironforge in the heart of the Red Ridge Mountains, to ask
          their elders what were those creatures. I suggest that we split in two
          groups. One of the groups will proceed to the lands of the northeast,
          while the other will go a bit southwards to visit the dwarves and
          gather some knowledge about the green-skins and the whole continent
          and its creatures as a whole. We will afterwards meet at our
          destination. Whatever our destination is” I hadn’t seen Hyjal in this
          form of speaking for years!
          <br />
          “This is a sound plan Hyjal” I was still amazed when I began my
          answer.
          <br />
          “I don’t know about anyone else but as for me Hyjal’s plan is perfect.
          As a matter of fact we will need a forest to inhabit, right? But even
          our ancients don’t know a thing about this continent we are about to
          reach and hopefully inhabit” I stopped.
          <br />
          “I have another suggestion” Elune suddenly said. “What if we really do
          so. I mean send scouts to the dwarven capital to learn about their
          culture and the lands, rivers, woods and lakes of the continent.
          Sounds like a plan! What I also suggest, though, is that the other
          group land on the island that lies just ahead, just to see if those
          green-skins are so invincible. Afterwards that group will catch up the
          others and we all will know where to live and what creatures inhabit
          this land” Elune stopped as suddenly as he started. “I am very proud
          of you guys when you talk like that” I revealed my feelings. “You told
          us a few nice plans and I guess this is what we will do. Elune, go and
          retell the crew your plan. Hyjal you are free, go and rest I’ll talk
          with you tomorrow and Kul’Dare – I want to ask you a bit more about
          the green-skins” I finished my speech excited. As I let out my last
          word everyone disappeared to handle his own task. I wondered for a
          moment whether we would finally find a place to live.
          <br />
          “You were saying” Kul’Dare asked me.
          <br />
          “Oh, yeah” I chased away my thoughts and turned towards Kul’Dare.
        </pre>
      </div>
      <Link to="/">Go back to the homepage</Link>
    </section>
  </Layout>
)

export default TheSmallVillageTavernPage
